<template>
  <div id="app">
      <div class="page">
          <Sindex />
      </div>
  </div>
</template>

<script>
    import Sindex from './components/LandingPage.vue'

export default {
  name: 'App',
        components: {
            Sindex
  }
}
</script>

<style>
    * {
        margin: 0;
    }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
    a {
        text-decoration: none;
        color: #0D0D0D;
    }
        a:hover {
            text-decoration: none;
            color: #000;
            opacity: 0.5;
        }

    .page {
        background: linear-gradient(-45deg, #8C7161, #BFA18F, #D9BBA9, #59453E, #0D0D0D);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        height: 100vh;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }
</style>
