<template>
    
    <div class="LPContainer">
        <div class="logo">
            <img alt="Vue logo" src="../assets/logo.png" class="logoImage">
        </div>

        <div class="whoami">
            <div class="myLinks">
                <font-awesome-icon icon="fa-brands fa-linkedin" /><a href="https://www.linkedin.com/in/ville-siivonen-85670998/" target="_blank"> LINKEDIN</a>
            </div>
            <div class="myLinks">
                <font-awesome-icon icon="fa-brands fa-github" /><a href="https://github.com/sMrai-san/" target="_blank"> GITHUB</a>
            </div>
        </div>
        <div class="copyRight">
            &copy; Ville Siivonen 2022
        </div>

    </div>
</template>

<script>


    export default {
        name: 'LandingPage',
        components: {

        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped>
    .logoImage {
        margin-top: 24px;
        max-width: 100%;
        height: auto;
    }

    .LPContainer {
        max-width: 100vw;
        overflow-x: hidden;
        overflow-y: hidden;
        margin: auto;
        padding: 10px;
    }

    .logo {
        transform-origin: center center;
        animation: zoom 15s infinite;
    }

    @keyframes zoom {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.05);
            /* equals 105% */
        }

        100% {
            transform: scale(1);
        }
    }

    .copyRight {
        position: absolute;
        letter-spacing: 2px;
        bottom: 16px;
        left: 52px;
        font-size: 14px;
    }

    .whoami {
        /* Bottom right text */
        position: absolute;
        bottom: 24px;
        right: 52px;
        color: #0D0D0D;
        opacity: 0.8;
        font-size: 28px;
    }

    .myLinks {
        padding: 8px;
    }

    @media only screen and (max-width: 460px) {
        .softCarousel{
            display: none;
        }
        .copyRight {
            position: relative;
            left: 8px;
            text-align: center;
            font-size: 10px;
        }
        .whoami {
            position: relative;
            right: 0;
            text-align: center;
            font-size: 16px;
        }
    }

</style>